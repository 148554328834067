<template>
  <div class="logs mt-4">
    <v-card>
      <v-data-table
        :footer-props="{
          'items-per-page-options': [10, 20, 50],
        }"
        :headers="headers"
        :items="logs"
        :items-per-page.sync="localPerPage"
        :loading="loading"
        :page.sync="localPage"
        :sort-by.sync="localSortBy"
        :sort-desc.sync="localSortDesc"
        :server-items-length="logsTotal"
      >
        <template v-slot:item.type="{ item }">
          <v-chip
            v-if="item.type"
            :class="`badge badge-small badge-${item.type}`"
            x-small
          >
            {{ $t(`severity_${item.type}`) }}
          </v-chip>
        </template>
        <template v-slot:item.message="{ item }">
          <p class="mt-2 mb-0">
            {{ item.message | alertMessage }}
            <v-btn
              v-if="item.message.toLowerCase() === 'balance mismatch'"
              icon
              x-small
              class="text--disabled ml-1"
              @click.stop="showDialogForAlert(item)"
            >
              <v-icon>fa-info-circle</v-icon>
            </v-btn>
          </p>
          <p class="mb-2 text--disabled">{{ item.reason }}</p>
        </template>
        <template v-slot:item.site="{ item }">
          <v-chip
            label
            outlined
            @click="
              $router.push({
                name: 'NetworkSiteDetails',
                params: { siteId: item.site.id },
              })
            "
          >
            {{ item.site.name }}
          </v-chip>
        </template>
        <template v-slot:item.date_introduced="{ item }">
          <span class="text--disabled">
            {{ item.date_introduced | dateTime }}
          </span>
        </template>
        <template v-slot:item.date_solved="{ item }">
          <span class="text--disabled">
            {{ item.date_solved | dateTime }}
          </span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import EventBus from "@/services/EventBus";

export default {
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    sortBy: {
      type: String,
      required: true,
    },
    sortDesc: {
      type: Boolean,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          text: this.$t("severity"),
          value: "type",
        },
        {
          text: this.$t("message"),
          value: "message",
        },
        {
          text: this.$t("site"),
          value: "site",
        },
        {
          text: this.$productOpt ? this.$tc("opts") : this.$t("device"),
          value: "opt_number",
        },
        {
          text: this.$t("date_introduced"),
          value: "date_introduced",
        },
        {
          text: this.$t("date_solved"),
          value: "date_solved",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      logs: (state) => state.log.logs,
      logsTotal: (state) => state.log.logsTotal,
    }),
    localSortBy: {
      get() {
        return this.sortBy;
      },
      set(value) {
        this.$emit("set-sort-by", value);
      },
    },
    localSortDesc: {
      get() {
        return this.sortDesc;
      },
      set(value) {
        this.$emit("set-sort-desc", value);
      },
    },
    localPage: {
      get() {
        return this.page;
      },
      set(value) {
        this.$emit("set-page", value);
      },
    },
    localPerPage: {
      get() {
        return this.perPage;
      },
      set(value) {
        this.$emit("set-per-page", value);
      },
    },
  },
  methods: {
    showDialogForAlert(alert) {
      EventBus.$emit("alert-details", alert);
    },
  },
};
</script>
