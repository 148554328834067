<template>
  <div class="logs mx-5 my-5">
    <div class="logs-header">
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <span>
              <v-icon
                x-small
                class="mr-2"
                :color="filterEnabled ? 'primary' : 'grey lighten-1'"
              >
                fas fa-filter
              </v-icon>
              {{ $t("filter") }}
            </span>
            <v-btn
              v-show="filterEnabled"
              text
              class="btn-clear"
              @click.stop="resetFilter"
            >
              {{ $t("clear") }}
            </v-btn>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <logs-filter
              :filter="filter"
              :filter-enabled="filterEnabled"
            ></logs-filter>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-btn color="primary" class="btn-refresh" @click="loadLogs">
        <v-icon left>fa-sync-alt</v-icon>
        {{ $t("refresh") }}
      </v-btn>
    </div>

    <logs-table
      :loading="loading"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      :page="page"
      :per-page="perPage"
      @set-sort-by="(e) => (sortBy = e)"
      @set-sort-desc="(e) => (sortDesc = e)"
      @set-page="(e) => (page = e)"
      @set-per-page="(e) => (perPage = e)"
    ></logs-table>
  </div>
</template>

<script>
import { debounce } from "lodash-es";
import { mapActions } from "vuex";
import LogsFilter from "../components/Logs/LogsFilter";
import LogsTable from "../components/Logs/LogsTable";

export default {
  name: "Logs",
  components: {
    LogsFilter,
    LogsTable,
  },
  data() {
    return {
      loading: true,
      sortBy: "date_introduced",
      sortDesc: true,
      page: 1,
      perPage: 20,
      filter: {
        date_from: null,
        date_till: null,
        type: null,
        is_solved: null,
        site: null,
        search: null,
      },
    };
  },
  computed: {
    filterEnabled() {
      return Object.values(this.filter).some((f) => f);
    },
  },
  watch: {
    filter: {
      deep: true,
      immediate: true,
      handler() {
        this.page = 1;
        this.loadLogs();
      },
    },
    sortBy() {
      this.loadLogs();
    },
    sortDesc() {
      this.loadLogs();
    },
    page() {
      this.loadLogs();
    },
    perPage() {
      this.loadLogs();
    },
  },
  methods: {
    ...mapActions(["getLogs"]),
    resetFilter() {
      Object.keys(this.filter).forEach((k) => {
        this.filter[k] = null;
      });
    },
    // Short debounce because changing the sorted column might also change the sort direction and reset the page
    loadLogs: debounce(function () {
      this.loading = true;
      this.getLogs({
        sortBy: this.sortBy,
        sortDesc: this.sortDesc,
        page: this.page,
        perPage: this.perPage,
        filter: this.filter,
      }).then(() => {
        this.loading = false;
      });
    }, 10),
  },
};
</script>
