<template>
  <div>
    <v-row>
      <v-col cols="12" sm="6" lg="3" xl="1" class="py-0">
        <v-text-field
          v-model="filter.date_from"
          type="date"
          :label="$t('from')"
          clearable
          :max="today"
          :placeholder="$t('date_placeholder')"
          :error="dateError"
          @keyup.enter="(e) => e.target.blur()"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6" lg="3" xl="1" class="py-0">
        <v-text-field
          v-model="filter.date_till"
          type="date"
          :label="$t('till')"
          clearable
          :max="today"
          :placeholder="$t('date_placeholder')"
          :error="dateError"
          @keyup.enter="(e) => e.target.blur()"
        ></v-text-field>
      </v-col>

      <v-col cols="12" sm="6" lg="3" class="py-0">
        <v-select
          v-model="filter.type"
          :label="$t('severity')"
          :items="types"
          clearable
        >
          <template slot="selection" slot-scope="data">
            <v-chip :class="`badge badge-${data.item}`">
              {{ $t(`severity_${data.item}`) }}
            </v-chip>
          </template>
          <template slot="item" slot-scope="data">
            <v-chip :class="`badge badge-${data.item}`">
              {{ $t(`severity_${data.item}`) }}
            </v-chip>
          </template>
        </v-select>
      </v-col>

      <v-col cols="12" sm="6" lg="3" class="py-0">
        <v-select
          v-model="filter.is_solved"
          :label="$t('solved')"
          :items="solved"
          clearable
        >
        </v-select>
      </v-col>

      <v-col cols="12" sm="6" lg="3" class="py-0">
        <v-autocomplete
          v-model="filter.site"
          :items="sitesByName"
          item-text="name"
          item-value="id"
          clearable
          :label="$t('site')"
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" sm="6" lg="6" class="pt-0">
        <v-text-field
          v-model.trim="debouncedSearch"
          :placeholder="$t('message')"
          clearable
          @keyup.enter="(e) => e.target.blur()"
        >
          <v-icon slot="prepend">fa-search</v-icon>
        </v-text-field>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { debounce } from "lodash-es";
import { mapGetters } from "vuex";

export default {
  props: {
    filter: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      today: null,
      types: ["none", "warning", "serious", "critical"],
      solved: [
        { text: this.$t("yes"), value: true },
        { text: this.$t("no"), value: false },
      ],
    };
  },
  computed: {
    ...mapGetters(["sitesByName"]),
    debouncedSearch: {
      get() {
        return this.filter.search;
      },
      set: debounce(function (value) {
        this.filter.search = value || null;
      }, 200),
    },
    dateError() {
      return this.filter.date_from > this.filter.date_till;
    },
  },
  mounted() {
    this.today = dayjs().format("YYYY-MM-DD");
  },
};
</script>
